import React, { useState, useEffect, useRef, useCallback } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Ticker from "./ticker";
import PhoneSupport from "./phoneSupport";
import SwitchLanguage from "./switchLanguage";
import NavbarMenu from "./navbarMenu";
import A365Logo from "./a365logo";
import SideBar from "./sideBar";
import { useTranslation } from "react-i18next";
// import Loader from "../loader/loader";

const Navbar = () => {
  const [geo, setGeo] = useState(null);
  const [whatsapp, setWhatsapp] = useState(null);
  const [isLanding, setIsLanding] = useState(false);
  const { i18n } = useTranslation();
  const lg = useMediaQuery("(min-width: 1024px)");
  const sm = useMediaQuery("(max-width: 767px)");

  /* 
  6: "Australia"
  49: "Costa Rica"
  52: "Mexico"
  135: "Usa"
  137: "Canada"
  157: "Uruguay"
  161: "Peru"
  164: "Argentina"
  165: "Chile"
  199: "España"
  */

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const chatCenter = (country_id) => {
    return [6, 49, 52, 135, 137, 157, 161, 164, 165, 199].includes(
      parseInt(country_id)
    );
  };

  const initWhatsApp = (today_day, today_hour, country_id, header_whatsapp) => {
    /*
     * Static data
     */

    let whatsapp_number = "";

    const todayMinutes = timeToMinutes(today_hour);
    const startMinutes = timeToMinutes("21:01");
    const endMinutes = timeToMinutes("02:00");

    const _rules = `
      (${country_id} == 164 && (
      (${todayMinutes} >= ${startMinutes}) || (${todayMinutes} <= ${endMinutes}) || 
      (${today_day} == 6 || ${today_day} == 7)
  )) || ${country_id} != 164`;

    // if (!_rules) {
    //   _rules = `(${today_day} === "6") || (${today_day} === "7" && "${today_hour}" < "22:01")`;
    // }

    if (eval(_rules)) {
      whatsapp_number = "5491122647575";
    } else {
      if (country_id === 164) {
        whatsapp_number = header_whatsapp.substring(1);
      } else {
        whatsapp_number = chatCenter(country_id)
          ? "5491122647575"
          : header_whatsapp.substring(1);
      }
      if (
        country_id === 155 ||
        country_id === 201 ||
        i18n.language === "pt-BR"
      ) {
        whatsapp_number = "5548988523408";
      }
    }
    setWhatsapp(whatsapp_number);

    /*
     * Using CDN to fetch the chat_support.json file
     */

    // try {
    //   const url =
    //     "https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/js/chat_support.json";
    //   const method = "GET";

    //   fetch(url, {
    //     method: method,
    //   })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       const condition = response;
    //       let whatsapp_number = "";

    //       let rules = "";
    //       const total = Object.keys(condition).length;
    //       let counter = 1;

    //       Object.keys(condition).forEach((idx) => {
    //         rules += `(${today_day} === ${idx}`;
    //         if (condition[idx]) {
    //           let time = "";
    //           const operator = condition[idx].match(/[<>]=?|===/gi);

    //           time =
    //             condition[idx].length > 0
    //               ? condition[idx].slice(
    //                   operator[0]?.length,
    //                   condition[idx].length
    //                 )
    //               : null;

    //           rules += ` && '${today_time}' ${operator} '${time}')`;
    //         } else {
    //           rules += ")";
    //         }
    //         if (counter < total) rules += " || ";
    //         counter++;
    //       });

    //       let _rules = `(${rules})`;

    //       if (!_rules) {
    //         _rules = `(${today_day} === "6") || (${today_day} === "7" && "${today_hour}" < "22:01")`;
    //       }

    //       if (eval(_rules)) {
    //         whatsapp_number = "5491122647575";
    //       } else {
    //         whatsapp_number = chatCenter(country_id)
    //           ? "5491122647575"
    //           : header_whatsapp.substring(1);
    //         if (
    //           country_id === 155 ||
    //           country_id === 201 ||
    //           i18n.language === "pt-BR"
    //         ) {
    //           whatsapp_number = "5548988523408";
    //         }
    //       }
    //       setWhatsapp(whatsapp_number);
    //     });
    // } catch (error) {
    //   throw new Error("Something went wrong");
    // }
  };

  useEffect(() => {
    if (geo && checkVisibility()) {
      localStorage.setItem("country_name", geo.country_name);
      localStorage.setItem("currency_symbol", geo.currency_symbol);
      localStorage.setItem("entity_id", geo.entity);
      localStorage.setItem("seller_id", geo.seller);
      localStorage.setItem("country_code", geo.country);
      localStorage.setItem("country_id", geo.country_id);
      localStorage.setItem("header_whatsapp", geo.header_whatsapp);
      localStorage.setItem("locale", geo.locale);

      let today_day = geo?.today_day;
      let today_hour = geo?.today_hour;

      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("today_day") || urlParams.has("today_hour")) {
        if (urlParams.get("today_day")) {
          today_day = urlParams.get("today_day").toString();
        }
        if (urlParams.get("today_hour")) {
          today_hour = urlParams.get("today_hour").toString();
        }
      }

      initWhatsApp(
        today_day,
        today_hour,
        geo?.country_id,
        geo?.header_whatsapp
      );
    }
  }, [geo]);

  const checkVisibility = () => {
    let app = location.pathname.split("/")[2] || null;

    const excluded_apps = [
      "assist-pay",
      "productos",
      "products",
      "checkout",
      "checkout2",
      "payment_success",
      "payment_pending",
      "mi-voucher",
      "afiliados",
      "preguntas-frecuentes",
    ];

    return !excluded_apps.includes(app);
  };

  const visibility = checkVisibility();

  useEffect(() => {
    if (visibility) {
      try {
        const geoLocation = async () => {
          const country_code =
            localStorage.getItem("lang") === "pt"
              ? "BR"
              : location.pathname.split("/")[1] || "ar";

          let query = `?flag=true&country_code=${country_code}`;

          return await fetch(
            `https://app.assist-365.com/api/geolocation${query}`
          )
            .then((response) => response.json())
            .then((response) => {
              setGeo(response);
            })
            .catch((err) => console.error(err));
        };
        geoLocation().catch(console.error);
      } catch (error) {
        console.error(error);
      }

      try {
        const splitted_path = location.pathname.split("/");

        if (splitted_path.length > 3) {
          const landings_path = [
            "tramites",
            "requisitos",
            "destinos",
            "viajes",
          ];
          setIsLanding(landings_path.includes(location.pathname.split("/")[2]));
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  return (
    <>
      {visibility && (
        <div className="navbar-w-full navbar-bg-[#0371e8] navbar-flex navbar-items-center navbar-justify-center navbar-flex-col">
          <Ticker country_id={geo?.country_id} />
          <div className="navbar-w-full navbar-max-w-[358px] sm:navbar-max-w-[633px] md:navbar-max-w-[753px] md:navbar-px-0 md:navbar-pr-4 md:navbar-justify-start xl:navbar-max-w-[1210px] xl:navbar-px-4 xl:navbar-pr-0 navbar-px-4 sm:navbar-pr-6 navbar-relative navbar-flex navbar-items-center navbar-justify-between navbar-h-[80px]">
            <div className="navbar-flex navbar-items-center">
              <a href={`https://assist-365.com`}>
                <A365Logo />
              </a>

              {!sm && <NavbarMenu whatsapp={whatsapp} isLanding={isLanding} />}
            </div>
            <div className="navbar-flex navbar-items-center xl:navbar-items-start navbar-justify-end">
              {!sm && geo?.country_id === 164 && i18n.language !== "pt-BR" && (
                <PhoneSupport />
              )}
              <SwitchLanguage />
              {sm && whatsapp && geo && (
                <SideBar
                  isLanding={isLanding}
                  whatsapp={whatsapp}
                  geo={geo}
                  lang={i18n.language}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
