import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        options: {
          myVoucher: "Minha Apólice",
          faqs: "Perguntas frequentes",
          sales: "Vendas",
        },
      },
    },
    es: {
      translations: {
        options: {
          myVoucher: "Mi voucher",
          faqs: "Preguntas Frecuentes",
          sales: "Ventas",
        },
      },
    },
    "es-AR": {
      translations: {
        options: {
          myVoucher: "Mi Voucher",
          faqs: "Preguntas Frecuentes",
          sales: "Ventas",
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
