import React from "react";
import logo from "./../navbar/logo_a365.svg";

const A365Logo = () => {
  return (
    <img
      src={logo}
      alt="ASSIST 365"
      className="navbar-w-[44px] navbar-h-auto md:navbar-w-[52px] md:navbar-h-auto"
    />
  );
};

export default A365Logo;
