import React, { useEffect, useState } from "react";
import "./ticker.css";

const Ticker = ({ country_id }) => {

  const [promoExtraData, setPromoExtraData] = useState(null);
  const [promo, setPromo] = useState(false);

  const capitalize = (s) => {
    if (s) {
      const str2 = s.charAt(0).toUpperCase() + s.slice(1);
      return str2;
    }
  };

  useEffect(() => {

    try {
      if (country_id) {

        const url = `https://app.assist-365.com/api/update-banner?country_id=${country_id}`;
        fetch(url)
          .then((response) => response.json())
          .then((promo) => {
            if (promo) {
              const {
                claim,
                condition,
                country_id,
                discount,
                dues,
                name,
                validity,
                fixed,
                custom_message
              } = promo.data;

              const promo_data = {
                claim: claim,
                status: promo.status,
                country_id: country_id,
                discount: discount,
                condition: capitalize(condition),
                dues: dues,
                validity: validity,
                name: name,
                fixed: fixed,
                custom_message: custom_message
              };

              setPromo(promo_data);

              if (promo_data.status) {

                promo_data.dues ? promo_data.dues = promo_data.dues : promo_data.dues = '';
                promo_data.condition ? promo_data.condition = promo_data.condition : promo_data.condition = '';

                const promo_discount = promo_data.discount;
                const promo_text = `${promo_data.condition} ${promo_data.dues} ${promo_data.validity}`;

                setPromoExtraData(
                  {
                    ...promoExtraData,
                    promo_discount: promo_discount,
                    promo_text: promo_text,
                    custom_message: promo_data.custom_message
                  }
                );
              }

            } else {
              setPromo(null);
            }

          });
      }
    } catch (error) { }
  }, [country_id]);


  return (
    <>
      <div className="ticker-container">
        <div className="fade-left"></div>
        {/* <p className="marquee">
          {promoExtraData?.custom_message ? (
            <span>{promoExtraData?.custom_message}</span>
          ) : (
            <span>{promoExtraData?.promo_discount} {promoExtraData?.promo_text}</span>
          )}
        </p>
        <p className="marquee marquee2">
          {promoExtraData?.custom_message ? (
            <>
              <span>{promoExtraData?.custom_message} {promoExtraData?.custom_message}</span>
            </>
          ) : (
            <>
              <span>{promoExtraData?.promo_discount} {promoExtraData?.promo_text}</span>
            </>
          )}
        </p> */}

        <div class="ticker-tape-container">
          <div class="ticker-tape">
            {promoExtraData?.custom_message ? (
              <span>{promoExtraData?.custom_message}</span>
            ) : (
              <span>{promoExtraData?.promo_discount} {promoExtraData?.promo_text}</span>
            )}
            {promoExtraData?.custom_message ? (
              <>
                <span>{promoExtraData?.custom_message} {promoExtraData?.custom_message}</span>
              </>
            ) : (
              <>
                <span>{promoExtraData?.promo_discount} {promoExtraData?.promo_text}</span>
              </>
            )}
          </div>
          <div class="ticker-tape" aria-hidden="true">
          {promoExtraData?.custom_message ? (
              <span>{promoExtraData?.custom_message}</span>
            ) : (
              <span>{promoExtraData?.promo_discount} {promoExtraData?.promo_text}</span>
            )}
            {promoExtraData?.custom_message ? (
              <>
                <span>{promoExtraData?.custom_message} {promoExtraData?.custom_message}</span>
              </>
            ) : (
              <>
                <span>{promoExtraData?.promo_discount} {promoExtraData?.promo_text}</span>
              </>
            )}
          </div>
        </div>
        <div className="fade-right"></div>
      </div >
    </>
  );
};

export default Ticker;
