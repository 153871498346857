import "./assets/css/tailwind.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { LanguageProvider } from "./context/LanguageContext";
import Navbar from "./components/navbar/navbar";

export default function Root(props) {
  return (
    <LanguageProvider>
      <I18nextProvider i18n={i18n}>
        <Navbar />
      </I18nextProvider>
    </LanguageProvider>
  );
}
