import OutsideClickHandler from "react-outside-click-handler";
import closeBtn from "./../../assets/img/closeBtn.svg";

const PhoneSupportDialog = ({ dialogVisibility }) => {
  return (
    <div className="navbar-bg-[rgba(0,0,0,0.4)] navbar-fixed navbar-top-0 navbar-bottom-0 navbar-left-0 navbar-right-0 navbar-w-full navbar-h-screen navbar-z-40 navbar-flex navbar-items-center navbar-justify-center">
      <OutsideClickHandler onOutsideClick={() => dialogVisibility(false)}>
        <div className="navbar-bg-white navbar-text-text-primary navbar-rounded-lg navbar-p-6 navbar-shadow-lg navbar-w-[90%] navbar-h-auto md:navbar-w-[585px] md:navbar-h-[240px] navbar-relative navbar-z-50 navbar-m-auto">
          <button
            onClick={() => dialogVisibility(false)}
            className="navbar-absolute navbar-right-4 navbar-top-5"
            type="button"
          >
            <img src={closeBtn} />
          </button>
          <h1 className="navbar-text-2xl navbar-font-bold navbar-text-left navbar-mb-3">
            ¡Sumamos atención teléfonica!
          </h1>
          <p className="navbar-text-sm">
            De Lunes a Viernes de 09 a 21hs, puedes comunicarte con nosotros si:
            <ul className="navbar-list-disc navbar-pl-4 navbar-pt-3">
              <li>
                Necesitas ayuda para elegir la asistencia al viajero ideal para
                tu viaje.
              </li>
              <li>
                Ya tienes tu voucher con nosotros y tienes dudas o precisas
                hacer cambios.
              </li>
            </ul>
          </p>
          <h2 className="navbar-text-2xl navbar-text-center navbar-font-bold navbar-mt-5">
            0810 - 345 - 0684
          </h2>
          <h3 className="navbar-text-blue-primary navbar-mx-auto navbar-text-center navbar-font-semibold navbar-font-lg">
            ¡No dudes en llamarnos!
          </h3>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default PhoneSupportDialog;
